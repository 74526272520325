import moment from 'moment';
import { RichText } from 'prismic-reactjs';
import * as queryString from "query-string";
import * as React from "react";
import { useEffect } from 'react';
import { Trans, useTranslation } from "react-i18next";
import { quizSerializer } from '../app/utilities/quizSerializer';
import Layout from "../components/layout";
import {
  Button, Label, RadioFields
} from "./FormElements";
require('../2020/index.scss');


export const query = graphql`
query QuizQuery($lang: String!) {
  prismic {
    allQuizs(lang: $lang) {
      edges {
        node {
          user_played
          success_message_cta
          success_message
          not_eligible_message
          redeem_coupon_button
          submit_quiz_button
          no_active_quiz_message
          login_message
          failure_message
          questions {
            answer_1
            answer_2
            answer_3
            answer_4
            correct_answer
            end_date
            question
            salesforce_coupon_week
            start_date
          }
        }
      }
    }
  }
  allPrismicQuiz(filter: {lang: {eq: $lang}}) {
    edges {
      node {
        data {
          failure_message {
            raw {
              type
              text
            }
          }
          login_message {
            html
            raw {
              type
              text
            }
          }
          no_active_quiz_message {
            raw {
              text
              type
            }
          }
          not_eligible_message {
            raw {
              type
              text
            }
          }
          questions {
            answer_1
            answer_2
            answer_3
            answer_4
            correct_answer
            end_date
            question
            salesforce_coupon_week
            start_date
          }
          user_played {
            raw {
              type
              text
            }
          }
          success_message_cta
          success_message {
            raw {
              text
              type
            }
          }
        }
      }
    }
  }
}
`
const Quiz = (props) => {
  const {pageContext, location, data} = props;
   
  const { i18n, t } = useTranslation('quiz');
  if (i18n.language !== pageContext.lang && pageContext.lang) {
    i18n.changeLanguage(pageContext.lang);
  }

  const { uid } = queryString.parse(location.search)
  let node = data.prismic.allQuizs.edges[0].node;
  
  let {questions} = node || [];
  
  questions.map(q => {
    q.answers = [];
    if (q.answer_1 !== '') q.answers.push({value: 'answer_1', label: q.answer_1})
    if (q.answer_2 !== '') q.answers.push({value: 'answer_2', label: q.answer_2})
    if (q.answer_3 !== '') q.answers.push({value: 'answer_3', label: q.answer_3})
    if (q.answer_4 !== '') q.answers.push({value: 'answer_4', label: q.answer_4})
    return q; 
  })

  const question = questions.filter(q => moment(new Date()).isBetween(q.start_date, q.end_date))[0] || null;
  
  const [coupon, setCoupon] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isLoggedIn, setIsLoggedIn] = React.useState(false);
  const [isEligible, setIsEligible] = React.useState(false);
  const [hasPlayed, setPlayed] = React.useState(false);
  const [formData, setFormData] = React.useState(null);
  const [isFailure, setIsFailure] = React.useState(null);
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  
  useEffect(() => {
    if (uid) {
      setIsLoggedIn(true)
    }
  }, [uid]);

  useEffect(() => {
      fetch(`/api/eligible?uid=${uid}&c=${question?.salesforce_coupon_week ?? 1}`)
      .then(res => res.json())
      .then(res => {
        
        if (!res.error) {
          setIsEligible(true)
          setFormData({uid, w: question.salesforce_coupon_week, c: res.c, t: res.t})
        }

        if (res.error) {  
          if (res.message === 'USER_PARTICIPATED') {
            setPlayed(true)
            setIsEligible(true)
          }
          if (res.message === 'USER_NOT_FOUND') {
            setPlayed(false)
            setIsEligible(false)
          }
        }
        setIsLoading(false)
      })
      .catch((error) => {
        console.error('Error:', error)
      });
  },[])

  const onSubmit = React.useCallback((evt) => {
    evt.preventDefault();
    
    if (isSuccess || isFailure || isSubmitting) { return false; }

    try {
      setIsSubmitting(true);
      async function postForm() {

        const requestOptions = {
          method: 'POST',
          'Content-Type': 'application/json',
          body: JSON.stringify(formData),
          redirect: 'follow',
          mode: 'cors',
        };
        try {
          const response = await fetch(`/api/redeem?r=${evt.target['answer'].value === question.correct_answer}`, requestOptions);
          const data = await response.json();
          setIsSubmitting(false);
          if (data && data.success === true) {
            setCoupon(data.coupon)
            setIsSuccess(true)
          }
          if (data && data.error === true) {
            setIsFailure(true)
          }
        } catch (err) {
          console.error(err);
          setIsSubmitting(false);
        }
      }
      postForm();
    } catch(error) {
      setIsSubmitting(false);
    }
  }, [isSubmitting, isSuccess, isFailure, formData]);

  if (!isLoggedIn) {
    return (
      <>
        <div className="o-form">
          <RichText render={node.login_message} htmlSerializer={quizSerializer} />
        </div>
      </>
    );
  }

  if (isLoading) {
    return (
      <>
        <div className="o-form">
          Loading ...
        </div>
      </>
    );
  }

  if(!question) {
    return (
      <>
        <div className="o-form">
          <RichText render={node.no_active_quiz_message} htmlSerializer={quizSerializer} />
        </div>
      </>
    );
  }

  if (!isEligible) {
    return (
      <>
        <div className="o-form">
          <RichText render={node.not_eligible_message} htmlSerializer={quizSerializer} />
        </div>
      </>
    );
  }

  if (hasPlayed) {
    return (
      <>
        <div className="o-form">
          <RichText render={node.user_played} htmlSerializer={quizSerializer} />
        </div>
      </>
    );
  }
  

  if (isSuccess) {
    return (
      <>
        <div className="o-form">
          <Trans i18nKey="successMessage" values={{coupon}} ns="quiz"/>
          <a href={`https://www.betcenter.be/voucher/${coupon}`} className="o-button" target="_parent">{node?.redeem_coupon_button || 'Redeem'}</a>
        </div>
      </>
    );
  }

  if (isFailure) {
    return (
      <>
        <div className="o-form">
          <RichText render={node.failure_message} htmlSerializer={quizSerializer} />
        </div>
      </>
    );
  }

  return (
    <Layout>
      <form name="quiz" method={"post"} onSubmit={onSubmit} className={"o-form o-form--reset"}>
        {question && (<>
          <Label text={question.question} required={true} title={t('required')}>
            <RadioFields name="answer" values={question.answers} first="" required={true}/>
          </Label>
          <Button name={node?.submit_quiz_button || 'Submit'} />
        </>)}
      </form>
    </Layout>
  );
}

export default Quiz
